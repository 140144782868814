<template>
  <div>
    <div class="half-size">
      <div class="field">
        <label class="label">{{ $t('products.name') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.name" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.product_line_head') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.product_line_head" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.product_line_sub') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.product_line_sub" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.size') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.size" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.url') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.url" />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('products.relevant_products_url') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.relevant_products_url" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.link_accessoires') }}</label>
        <div class="field-control">
          <input type="text" v-model="product.link_accessoires" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.price') }}</label>
        <div class="field-control">
          <input type="number" :placeholder="product.calculated_price" disabled />
        </div>
      </div>

      <div class="field">
        <label class="label" for="description">{{ $t('products.description') }}</label>
        <Redactor placeholder="Type hier uw beschrijving.." id="description" v-model="product.description"
                  :config="redactorConfig" />
      </div>

      <div class="field field--push-medium field--checkbox" style="margin-top: 48px">
        <input type="checkbox" id="isVisible" v-model="product.is_visible" />
        <div class="field-control">
          <label for="isVisible">Zichtbaar</label>
        </div>
      </div>

      <div class="field field--push-medium field--checkbox">
        <input type="checkbox" id="isConfigurable" v-model="product.is_configurable" />
        <div class="field-control">
          <label for="isConfigurable">Is te configureren</label>
        </div>
      </div>

      <div class="field field--push-medium field--checkbox">
        <input type="checkbox" id="hasDifferentColors" v-model="product.has_multiple_colors" />
        <div class="field-control">
          <label for="hasDifferentColors">Verschillende kleuren</label>
        </div>
      </div>

      <div class="field field--push-hard field--checkbox">
        <input type="checkbox" id="hasDiscount" v-model="product.has_discount" />
        <div class="field-control">
          <label for="hasDiscount">Korting</label>
        </div>
      </div>

      <div v-if="product.has_discount" class="field field--push-hard">
        <label class="label" for="hasDiscount">Prijs voor korting (Alleen Steelux)</label>
        <div class="field-control">
          <input type='number' step='0.01' value='0.00' placeholder='0.00'
                 v-model="product.price_pre_discount" />
        </div>
      </div>

      <div v-if="product.has_discount" class="field field--push-hard">
        <label class="label" for="hasDiscount">Kortingspercentage (Alleen Povag)</label>
        <div class="field-control">
          <input type='number' step='0.01' value='0.00' placeholder='0.00'
                 v-model="product.discount_percentage" />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.suggestion') }}</label>
        <div class="field-control">
          <textarea placeholder="Type hier uw suggestie tekst.." v-model="product.suggestion"></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('products.subcategory') }}</label>
        <div class="field-control">
          <multiselect v-model="product.categories"
                       :options="categoriesList"
                       label="name"
                       track-by="id"
                       :close-on-select="false"
                       :limit="3"
                       :taggable="true"
                       placeholder="Selecteer subcategorie "
                       :multiple=true />
        </div>
      </div>

      <div v-for="i of [1,2,3,4,5,6,7,8]" class="field">
        <label class="label">{{
            $t('products.tariff_rate', [i + ' (€' + tariffs['tariff_' + i] + ')'])
                             }}</label>
        <div class="field-control">
          <input type="number" v-model="product.tariff_piece['tariff_' + i]" />
        </div>
      </div>
    </div>

    <a href="#" class="btn btn--orange" @click.prevent="save">
      {{ $t('products.save') }}
    </a>
  </div>
</template>

<script>
import store from '../../../store';
import Multiselect from 'vue-multiselect';
import Redactor from '../../../components/redactor-text-area'

export default {
  components: { Multiselect, Redactor },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch('categories/fetch_categories');

    next();
  },

  data() {
    return {
      tariffs: {
        tariff_1: process.env.MIX_TARIFF_1,
        tariff_2: process.env.MIX_TARIFF_2,
        tariff_3: process.env.MIX_TARIFF_3,
        tariff_4: process.env.MIX_TARIFF_4,
        tariff_5: process.env.MIX_TARIFF_5,
        tariff_6: process.env.MIX_TARIFF_6,
        tariff_7: process.env.MIX_TARIFF_7,
        tariff_8: process.env.MIX_TARIFF_8,
      },
      redactorConfig: {
        buttons: ['bold', 'html', 'lists', 'italic'],
      },
    };
  },

  computed: {
    product() {
      return this.$store.state.products.product;
    },
    categoriesList() {
      const stateCategories = this.$store.state.categories.categories;
      return stateCategories.filter(category => {
        return category.category_id !== null
      });
    },
  },

  watch: {
    url: function (newVal) {
      if (!newVal.length) {
        this.url = '/';
        return;
      }

      if (newVal[0] !== '/') {
        this.url = '/' + newVal;
      }
    },
  },

  methods: {
    save() {
      this.$store.commit('products/set_product', this.product);

      this.$store.dispatch('products/update_product').then(response => {
        this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
      }).catch((err) => {
        const errors = Object.entries(err.response.data.errors)
            .map((err) => err[1])
            .flat()
            .join("<br />");

        this.$flashMessage(errors, 3500, 'error');
      });
    },
  },
}
</script>
