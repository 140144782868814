import { uuid } from '../../helpers';
import Answer from "./Answer";

export default class Question {
    constructor(id, question_group_id, default_answer_id, text, dependent_question_id, dependent_answer_id, explanation, answers) {
        this.id = !id ? uuid() : id;
        this.question_group_id = question_group_id;
        this.default_answer_id = default_answer_id;
        this.text = text;
        this.dependent_question_id = dependent_question_id || null;
        this.dependent_answer_id = dependent_answer_id || null;
        this.explanation = explanation;
        this.answers = answers.map(a => Answer.make(a)) || [];
    }

    static make(obj) {
        return new this(obj.id, obj.question_group_id, obj.default_answer_id, obj.text, obj.dependent_question_id, obj.dependent_answer_id, obj.explanation, obj.answers || []);
    }

    static makeEmpty(question_group_id) {
        return new this(null, question_group_id, null, '', null, null, null, []);
    }

    exists() {
        return !this.id.toString().startsWith('_');
    }
}
