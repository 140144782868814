<template>
    <div ref="productInput">
        <div class="field-control field-control--tags" :class="{'field-control--tags--empty': selected === null}">
            <div v-if="selected !==  null" :key="selected.id" class="tag">
                <div class="tag-title tag-title--push-bottom">
                    {{ selected.name }}

                    <span class="tag-remove" @click="removeItem(selected)">
                        <img src="/svgs/close-icon.svg" alt="close-icon" />
                    </span>
                </div>
            </div>

            <input type="text" v-model="search" @input="onSearchInput" @focus="active = true" />
            <ul class="tag-list" v-if="active">
                <li v-for="item in searchResults" class="tag-list-item" :key="item.id" @click="selectItem(item)" :class="{'active': isSelectedItem(item)}">
                    {{ item.name }}

                    <img src="/svgs/checkmark.svg" v-if="isSelectedItem(item)" />
                </li>

                <li class="tag-list-item" v-if="! searchResults.length && searched">
                    No results
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { ProductService } from '../../../services'

    export default {
        name: 'product-input',

        props: ['value', 'maxSelection'],

        data() {
            return {
                active: false,
                search: null,
                searchResults: [],
                selected: this.value,
                searched: false,
            }
        },

        watch: {
            selected: function (newVal) {
                this.$emit('input', newVal);
            }
        },

        methods: {
            isSelectedItem(item) {
                if(this.selected === null) return false;
                return this.selected.id === item.id;
            },
            onSearchInput: debounce(function () {
                this.searched = true;
                ProductService.search({ searchQuery: this.search }).then(response => {
                    this.searchResults = response.data;
                });

            }, 500),

            selectItem(item) {
                this.selected = item;
            },

            removeItem(item) {
                this.selected = null;
            },
        },

        mounted() {
            document.addEventListener('click', (e) => {
                if (!this.active) {
                    return;
                }

                const productInput = this.$refs.productInput;
                let targetEl = e.target;

                do {
                    if (targetEl === productInput) {
                        return;
                    }

                    targetEl = targetEl.parentNode;
                } while (targetEl);

                this.active = false;
                this.searchResults = [];
                this.searched = false;
            });
        },
    }
</script>
