<template>
    <div class="category-filters">
        <table class="table">
            <thead>
                <tr>
                    <th class="status">Status</th>
                    <th>Producten</th>
                </tr>
            </thead>
            <draggable @end="savePriority($event)" tag="tbody" v-model="category.product_category">
                <tr :key="'product_category_' + pivot.product.id"
                    :id="pivot.product.id"
                    v-for="pivot in category.product_category">
                    <td class="status">
                        <div class="indicator" :class="{'green': pivot.product.is_visible,'red': !pivot.product.is_visible}" />
                    </td>
                    <td>{{ pivot.product.name }}</td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: "FiltersTab",
        components: {
            draggable
        },
        props: {
            category: {
                required: true
            },
        },
        methods: {
            savePriority(e) {
                this.category.product_category = this.category.product_category.map((pivot, index) => {
                    pivot.priority = index + 1;
                    return pivot;
                });
                this.$store.commit('categories/set_category', this.category)
            },
        }
    }
</script>
