<template>
    <div class="product-detail">
        <div class="page-header">
            <h1 class="page-title">{{ product.name }}</h1>
        </div>

        <div class="tabs tabs--push-less">
            <router-link :to="`/producten/${product.id}/algemene-gegevens`">
                {{ $t('general.general_information') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/configurator-vragen`">
                {{ $t('general.configurator_questions') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/afbeeldingen`">
                {{ $t('general.images') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/specificaties`">
                {{ $t('specifications.tab_title') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/pros-and-cons`">
                {{ $t('pros_and_cons.tab_title') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/relevante-producten`">
                {{ $t('relevant_products.tab_title') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/filters`">
                {{ $t('filters.tab_title') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/accessoires`">
                {{ $t('accessories.tab_title') }}
            </router-link>
            <router-link :to="`/producten/${product.id}/meta`">
                {{ $t('products.meta') }}
            </router-link>
        </div>

        <router-view :product="product"/>
    </div>
</template>

<script>
    import store from '../../../store';

    export default {
        async beforeRouteEnter(to, from, next) {
            await store.dispatch('products/fetch_product', to.params.id)
                .then(response => {
                    next();
                });
        },

        computed: {
            product() {
                return this.$store.state.products.product;
            },
        },
    };
</script>
