<template>
    <div class="product-detail">
        <div class="page-header">
            <h1 class="page-title">{{$t('choice_help.new_answer')}}</h1>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('choice_help.answer_title') }}</label>
                <div class="field-control">
                    <input type="text" v-model="answer.title" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('choice_help.answer_description') }}</label>
                <div class="field-control">
                    <input type="text" v-model="answer.description" />
                </div>
            </div>

            <div class="field">
                <label class="label" for="header_image_input">{{ $t('choice_help.image') }}</label>
                <div class="images-container news">
                    <label v-if="answer.image_path === null"
                           for="header_image_input"
                           class="btn btn--blue">
                        {{ $t('images.add_image') }}
                    </label>

                    <input id="header_image_input"
                           name="image-input"
                           accept="image/*"
                           type="file"
                           @change="(e) => { handleImageUpload(e,answer.image_path) }">

                    <div class="images news">
                        <div v-if="answer.image_path" class="image">
                            <div @click="answer.image_path = null">
                                <img src="/svgs/close-icon.svg" alt="close-icon">
                            </div>
                            <img class="preview" v-if="answer.image_path"
                                 :src="answer.image_path" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('choice_help.filter_option') }}</label>

                <filters-input v-if="answer.filter_option" :max-selection="1" v-model="answer.filter_option" />
            </div>
            <div class="field">
                <label class="label">{{ $t('choice_help.follow_up_question') }}</label>
                <questions-input v-if="answer.follow_up_question" :max-selection="1"
                                 v-model="answer.follow_up_question" />
            </div>
        </div>
        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('choice_help.save_question') }}
        </a>
    </div>
</template>
<script>
    import { ChoiceHelpAnswerService } from '../../../services';
    import filtersInput from '../components/FiltersInput'
    import questionsInput from '../components/QuestionsInput'
    import Question from "../../products/components/Question";
    import imageCompression from 'browser-image-compression';

    export default {
        name: 'choice-help-answer-create',

        components: {
            Question,
            filtersInput,
            questionsInput,
        },

        data() {
            return {
                answer: {
                    image_path: null,
                    filter_option: [],
                    follow_up_question: [],
                    question_id: this.$route.params.id
                },
            };
        },

        methods: {
            addImage(file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    this.answer.image_path = e.target.result;
                };
            },
            async handleImageUpload(e) {
                const imageFile = e.target.files[0];
                const options = {
                    maxSizeMB: 3,
                    maxWidthOrHeight: 1680,
                    useWebWorker: false
                };
                try {
                    const compressedFile = await imageCompression(imageFile, options);
                    this.addImage(compressedFile)
                } catch (error) {
                    console.error(error);
                }
            },
            save() {
                ChoiceHelpAnswerService.store(this.answer, this.$route.params.id).then(response => {
                    this.$router.push('/keuzehulpvragen/' + response.data.question_id + '/antwoorden/' + response.data.id);
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");
                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    }
</script>
