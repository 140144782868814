<template>
    <div class="field-control">
        <div ref="picker"></div>
        <input type="text" v-model="color" @click="showPicker" @input="emitColor" @blur="setPickerColor" />
    </div>
</template>

<script>
    import Pickr from '@simonwep/pickr';

    export default {
        name: 'color-picker-input',

        props: ['value'],

        data() {
            return {
                color: this.value,
                picker: null,
            };
        },

        mounted() {
            this.initPicker();
        },

        methods: {
            initPicker() {
                const el = this.$refs.picker;

                this.picker = Pickr.create({
                    el: el,
                    theme: 'monolith',
                    closeOnScroll: true,
                    lockOpacity: true,
                    default: this.color,

                    components: {
                        preview: true,
                        hue: true,
                    }
                });

                this.picker.on('change', (color) => {
                    this.color = color.toHEXA().toString();
                    this.$emit('input', this.color);
                });
            },

            showPicker() {
                this.picker.show();
            },

            emitColor() {
                this.$emit('input', this.color);
            },

            setPickerColor() {
                this.picker.setColor(this.color);
            },
        },
    }
</script>

<style>
    .pickr {
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
    }
</style>
