<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img alt="search-icon" src="/svgs/search-icon.svg">
                <input @input="onSearchInput" class="search" type="text" v-model="searchQuery" />
            </div>

            <div>
                <a @click.prevent="createSpecificationCategory" class="btn btn--secondary btn--push-right" href="#">
                    {{ $t('specifications.add_category') }}
                </a>
                <a @click.prevent="createSpecification" class="btn btn--primary" href="#">
                    {{ $t('specifications.add_specification') }}
                </a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('specifications.category') }}</th>
                    <th>{{ $t('specifications.key') }}</th>
                    <th>{{ $t('specifications.value') }}</th>
                </tr>
            </thead>
            <draggable @end="updatePosition" tag="tbody" v-model="specifications">
                <tr :id="specification.id" :key="'specification_' + specification.id"
                    @click="editSpecification(specification.id)"
                    v-for="specification in specifications">
                    <td>{{ specification.product_specification_category_title }}</td>
                    <td>{{ specification.key }}</td>
                    <td>{{ specification.value }}</td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
    import { SpecificationService } from '../../../services';
    import debounce from 'lodash/debounce';
    import draggable from 'vuedraggable'

    export default {
        name: 'product-specifications-tab',

        data() {
            return {
                searchQuery: '',
                specifications: null,
            };
        },

        components: {
            draggable,
        },

        beforeMount() {
            this.fetchSpecifications();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchSpecifications();
            }, 250),

            fetchSpecifications() {
                const productId = this.$route.params.id;

                SpecificationService.getSpecifications(productId, this.searchQuery).then(response => {
                    this.specifications = response.data;
                });
            },

            editSpecification(specificationId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.specification.edit',
                    params: {
                        id: productId,
                        specification: specificationId
                    }
                });
            },

            createSpecification() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.specification.create',
                    params: {
                        id: productId
                    }
                });
            },

            createSpecificationCategory() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.specification-category.create',
                    params: {
                        id: productId
                    }
                });
            },

            updatePosition(e) {
                SpecificationService.updatePosition(this.specifications[e.newIndex].id, e.newIndex + 1);
            },
        },
    }
</script>
