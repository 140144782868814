<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" @input="onSearchInput" />
            </div>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="createQuestion">{{ $t('products.add_question') }}</a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('products.question') }}</th>
                    <th>{{ $t('products.question_type') }}</th>
                    <th></th>
                </tr>
            </thead>
        </table>

        <question-group-list v-if="questionGroups.length" :question-list="questionGroups" :level="1" @change="fetchQuestions" />
    </div>
</template>

<script>
    import { ProductQuestionService } from '../../../services';
    import debounce from 'lodash/debounce';
    import QuestionGroupList from '../components/QuestionGroupList';

    export default {
        name: 'product-questions-tab',

        components: { QuestionGroupList },

        data() {
            return {
                searchQuery: '',
                questionGroups: [],
            };
        },

        beforeMount() {
            this.fetchQuestions();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchQuestions();
            }, 250),

            fetchQuestions() {
                const productId = this.$route.params.id;
                this.questionGroups = [];

                ProductQuestionService.getQuestionGroups(productId, this.searchQuery).then(response => {
                    // Timout required for refreshing table
                    setTimeout(() => {
                        this.questionGroups = response.data;
                    }, 50);
                })
            },

            editQuestion(questionId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.configurator.edit',
                    params: {
                        id: productId,
                        question: questionId
                    }
                });
            },

            createQuestion() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.configurator.create',
                    params: {
                        id: productId
                    }
                });
            },
        },
    }
</script>
