import BaseService from './index';

const baseApiPath = process.env.MIX_API_BASE_PATH;

export default {
    index(params) {
        return BaseService.get(`/products`, params)
    },

    all() {
        return BaseService.get(`/products/all`)
    },

    show(productId) {
        return BaseService.get(`/products/${productId}`);
    },

    search(params) {
        return BaseService.get(`${baseApiPath}/products/search`, params)
    },

    update(productId, params) {
        return BaseService.put(`/products/${productId}`, params);
    },

    save(params) {
        return BaseService.post('/products', params);
    },

    loadImages(productId) {
        return BaseService.get('/products/' + productId + '/images');
    },

    storeProductImage(productId, base64) {
        return BaseService.post(`/products/${productId}/images`, { image: base64 });
    },

    storeProductVideo(productId, youtubeId) {
        return BaseService.post(`/products/${productId}/videos`, { youtube: youtubeId });
    },

    updateProductImagePosition(productImageId, position) {
        return BaseService.put(`/product-images/${productImageId}/position`, { position });
    },

    deleteProductImage(productImageId) {
        return BaseService.post('/product-images/' + productImageId + '/delete');
    },

    deleteProductVideo(productId) {
        return BaseService.get('/products/' + productId + '/video/delete');
    },

    loadVideos(productId) {
        return BaseService.get('/products/' + productId + '/videos');
    },

    getMeta(productId) {
        return BaseService.get('/products/' + productId + '/meta');
    },

    updateMeta(productId) {
        return BaseService.post('/products/' + productId + '/meta');
    }
}
