import { uuid } from '../../helpers';

export default class Answer {
    constructor(id, question_id, text, color, secondary_color, image, product, amount, has_custom_color, custom_color_label_1, custom_color_label_2, articles) {
        this.id = !id ? uuid() : id;
        this.question_id = question_id;
        this.text = text;
        this.color = color;
        this.secondary_color = secondary_color;
        this.image = image;
        this.product = product;
        this.amount = amount;
        this.has_custom_color = has_custom_color;
        this.custom_color_label_1 = custom_color_label_1;
        this.custom_color_label_2 = custom_color_label_2;
        this.articles = articles.map(a => {
            return { id: a.id, title: a.title, code: a.code }
        }) || [];
    }

    static make(obj) {
        return new this(obj.id, obj.question_id, obj.text, obj.color, obj.secondary_color, obj.image, obj.product, obj.amount, obj.has_custom_color, obj.custom_color_label_1, obj.custom_color_label_2, obj.articles || []);
    }

    static makeEmpty(question_id) {
        return new this(null, question_id, null, null, null, null, null,0, false, null, null, []);
    }

    exists() {
        return !this.id.toString().startsWith('_');
    }
}
