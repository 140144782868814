<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('accessories.add_accessories_title') }}</h1>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.accessoryProduct" @click.prevent="isDeleteModalActive = true">
                    {{ $t('accessories.delete') }}
                </a>

                <a href="#" class="btn btn--orange" @click.prevent="saveAccessory">
                    {{ $t('accessories.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('accessories.product') }}</label>
                <div class="field-control">
                    <multiselect v-model="accessoryProduct"
                                 :options="products"
                                 label="name"
                                 track-by="id"
                                 :loading="isFetchingProducts"
                                 @search-change="searchMultiselectProducts"
                                 placeholder="Selecteer producten" />
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('accessories.delete_modal.title')"
                   :content="$t('accessories.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteAccessory" />
    </div>
</template>

<script>
    import { ProductService, AccessoryService } from '../../services';
    import BaseModal from '../../components/BaseModal';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'product-accessories',

        components: { BaseModal, Multiselect },

        beforeRouteEnter(to, from, next) {
            if (to.params.accessoryProduct) {
                AccessoryService.getAccessory(to.params.accessoryProduct).then(response => {
                    next(vm => {
                        vm.accessoryProduct = response.data.product;
                    })
                }).catch((err) => {
                    next({
                        name: 'products.accessories',
                        params: { id: to.params.id },
                    });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                accessoryProduct: null,
                products: [],
                isDeleteModalActive: false,
                isFetchingProducts: false,
            };
        },

        beforeMount() {
            this.fetchProducts();
        },

        methods: {
            fetchProducts() {
                ProductService.index().then(response => {
                    this.products = response.data.data.filter(p => p.id !== parseInt(this.$route.params.id));
                });
            },

            searchMultiselectProducts(query) {
                this.isFetchingProducts = true;

                const params = {
                    filters: {
                        name: query
                    }
                };

                ProductService.index(params).then(response => {
                    this.products = response.data.data;
                    this.isFetchingProducts = false;
                })
            },

            store(productId, params) {
                AccessoryService.store(productId, params).then(response => {
                    this.$flashMessage(this.$t('accessories.save_success'), 3500, 'success');
                    this.$router.push({ name: 'products.accessories', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(accessoryProductId, productId, params) {
                AccessoryService.update(accessoryProductId, params).then(response => {
                    this.$flashMessage(this.$t('accessories.update_success'), 3500, 'success');
                    this.$router.push({ name: 'products.accessories', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveAccessory() {
                const productId = this.$route.params.id;
                const accessoryProductId = this.$route.params.accessoryProduct;

                const params = {
                    product_accessory_id: this.accessoryProduct.id,
                };

                if (!accessoryProductId) {
                    this.store(productId, params);
                    return;
                }

                this.update(accessoryProductId, productId, params);
            },

            deleteAccessory() {
                const productId = this.$route.params.id;
                const accessoryProductId = this.$route.params.accessoryProduct;

                AccessoryService.delete(accessoryProductId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.accessories',
                            params: { id: productId },
                        }
                    );
                });
            },
        },
    };
</script>
