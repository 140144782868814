<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('questions.new_question_title') }}</h1>
                <h2 class="page-title--sub">{{ product.name }}</h2>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.question" @click.prevent="isDeleteModalActive = true">
                    {{ $t('questions.delete') }}
                </a>

                <a href="#" class="btn btn--orange" @click.prevent="saveQuestionGroup">
                    {{ $t('questions.save') }}
                </a>
            </div>
        </div>

        <div class="question-configurator">
            <div>
                <div class="btn-toggle-wrap btn-toggle-wrap--push-bottom" :class="{'btn-toggle-wrap--disabled': questionGroup.exists()}">
                    <button class="btn-toggle" :class="{'btn-toggle--active': !questionGroup.is_compound}" @click="! questionGroup.exists() && changeQuestionGroupType(false)">
                        {{ $t('questions.default_question') }}
                    </button>

                    <button class="btn-toggle" :class="{'btn-toggle--active': questionGroup.is_compound}" @click="! questionGroup.exists() && changeQuestionGroupType(true)">
                        {{ $t('questions.compound_question') }}
                    </button>
                </div>

                <Question v-for="question in questionGroup.questions" :key="'question_' + question.id" :question="question" />

                <div class="new-row" @click="addNewQuestion" v-if="questionGroup.is_compound || ! questionGroup.questions.length">
                    <div class="new-row__circle">
                        <img src="/svgs/plus.svg">
                    </div>

                    <span>{{ $t('questions.add_question') }}</span>
                </div>
            </div>

            <div>
                <h4 v-if="!questionGroup.has_children" >{{ $t('questions.parent_question') }}</h4>
                <div v-if="!questionGroup.has_children" class="field" :class="{'field--push-harder': !questionGroup.parent_question_id}">
                    <div class="field-control">
                        <select @change="onChangeParentQuestion">
                            <option value=""></option>
                            <option v-for="simpleQuestionGroup in simpleQuestionGroups"
                                    v-if="simpleQuestionGroup.id !== questionGroup.id"
                                    :value="simpleQuestionGroup.questions[0].id"
                                    :selected="simpleQuestionGroup.questions[0].id === questionGroup.parent_question_id">
                                {{ simpleQuestionGroup.questions[0].text }}
                            </option>
                        </select>
                    </div>
                </div>

                <div  class="field field--push-harder" v-if="!!questionGroup.parent_question_id ">
                    <div class="field-control">
                        <select @change="onChangeParentQuestionAnswer">
                            <option value=""></option>
                            <option v-for="answer in parentQuestionAnswers"
                                    :value="answer.id"
                                    :selected="answer.id === questionGroup.parent_suggested_answer_id">
                                {{ answer.text }}
                            </option>
                        </select>
                    </div>
                </div>

                <h4>{{ $t('general.required') }}</h4>
                <div class="field field--push-harder field--checkbox" @click="onIsRequired">
                    <input type="checkbox" :checked="questionGroup.is_required" />
                    <div class="field-control">
                        <label>{{ $t('general.yes') }}</label>
                    </div>
                </div>

                <div v-if="! isCompound">
                    <h4>{{ $t('questions.multiple_answers_possible') }}</h4>
                    <div class="field field--no-margin field--checkbox" @click="onHasMultipleAnswers">
                        <input type="checkbox" :checked="questionGroup.has_multiple_answers" />
                        <div class="field-control">
                            <label>{{ $t('general.yes') }}</label>
                        </div>
                    </div>
                </div>

                <div v-if="isCompound">
                    <h4>{{ $t('questions.number') }}</h4>
                    <div class="field">
                        <div class="field-control">
                            <input type="text" v-model="questionGroup.amount" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('questions.delete_modal.title')"
                   :content="$t('questions.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteQuestionGroup" />
    </div>
</template>

<script>
    import { ProductQuestionService } from '../../services';
    import store from '../../store';
    import Question from './components/Question';
    import QuestionGroup from '../../models/products/QuestionGroup';
    import BaseModal from '../../components/BaseModal';

    export default {
        name: 'product-configurator',

        components: {
            BaseModal,
            Question
        },

        async beforeRouteEnter(to, from, next) {
            const productId = parseInt(to.params.id);

            await store.dispatch('products/fetch_product', productId);

            if (to.params.question) {
                const promise1 = store.dispatch('questions/fetch_question_group', to.params.question);
                const promise2 = store.dispatch('questions/fetch_simple_question_groups', productId);

                Promise.all([promise1, promise2]).then((response) => {
                    const response1 = response[0];

                    // Redirect to question group list if user try to bypass question group id
                    if (response1.data.product_id !== productId) {
                        next({ name: 'products.configurator', params: { id: productId } });

                        return;
                    }

                    next();
                });
            } else {
                store.commit('questions/set_question_group', QuestionGroup.makeEmpty(productId));
                store.dispatch('questions/fetch_simple_question_groups', productId).then(() => next());
            }
        },

        data() {
            return {
                isDeleteModalActive: false,
            };
        },

        computed: {
            product() {
                return this.$store.state.products.product;
            },

            questionGroup() {
                return this.$store.state.questions.questionGroup;
            },

            isCompound() {
                return this.$store.getters['questions/is_compound'];
            },

            simpleQuestionGroups() {
                return this.$store.state.questions.simpleQuestionGroups
            },

            parentQuestionAnswers() {
                if (!!this.questionGroup.parent_question_id) {
                    if (!! this.simpleQuestionGroups) {
                        return this.simpleQuestionGroups.find(sqg => sqg.questions[0].id === this.questionGroup.parent_question_id)
                            .questions[0]
                            .answers;
                    }
                }
                return [];
            },
        },

        methods: {
            changeQuestionGroupType(isCompound) {
                this.$store.commit('questions/change_question_group_type', isCompound);
            },

            onHasMultipleAnswers() {
                this.$store.commit('questions/toggle_has_multiple_answers');
            },

            onIsRequired() {
                this.$store.commit('questions/toggle_is_required');
            },

            onChangeParentQuestion(e) {
                this.$store.commit('questions/change_parent_question', parseInt(e.target.value));
            },

            onChangeParentQuestionAnswer(e) {
                this.$store.commit('questions/change_parent_answer', parseInt(e.target.value));
            },

            addNewQuestion() {
                this.$store.commit('questions/add_new_question', {
                    questionGroupId: this.$route.params.question,
                });
            },

            saveQuestionGroup() {
                this.$store.dispatch('questions/store').then(response => {
                    if (!response) {
                        return;
                    }

                    if (!this.questionGroup.exists()) {
                        this.$router.push({
                            name: 'products.configurator.edit',
                            params: {
                                id: this.$route.params.id,
                                question: response.data.id,
                            },
                        });

                        this.$flashMessage(this.$t('questions.save_success'), 3500, 'success');
                    } else {
                        this.$flashMessage(this.$t('questions.update_success'), 3500, 'success');
                    }
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            deleteQuestionGroup() {
                const productId = this.$route.params.id;
                const questionId = this.$route.params.question;

                ProductQuestionService.delete(questionId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.configurator',
                            params: { id: productId },
                        }
                    );
                });
            },
        },
    };
</script>
