import { ProductQuestionService } from '../../services';
import Answer from '../../models/products/Answer';
import Question from "../../models/products/Question";
import QuestionGroup from "../../models/products/QuestionGroup";

const state = {
    questionGroup: {},
    simpleQuestionGroups: [],
};

const getters = {
    questions: state => state.questionGroup.questions,
    is_compound: state => state.questionGroup.is_compound,
    answers_by_question: state => id => state.questionGroup.questions.find(q => q.id === id).answers,
};

const actions = {
    fetch_question_group({ commit }, questionGroupId) {
        const promise = ProductQuestionService.getQuestionGroup(questionGroupId);

        promise.then(response => {
            commit('set_question_group', response.data);
        });

        return promise;
    },

    fetch_simple_question_groups({ commit }, productId) {
        const promise = ProductQuestionService.getSimpleQuestionGroups(productId);

        promise.then(response => {
            commit('set_simple_question_groups', response.data);
        });

        return promise;
    },

    store({ commit }) {
        let promise;

        if (!state.questionGroup.exists()) {
            return ProductQuestionService.store(state.questionGroup.product_id, state.questionGroup);
        } else {
            promise = ProductQuestionService.update(state.questionGroup.id, state.questionGroup);

            promise.then(response => {
                commit('set_question_group', QuestionGroup.makeEmpty(state.questionGroup.product_id));

                setTimeout(() => {
                    commit('set_question_group', response.data);
                }, 1);
            });

            return promise;
        }
    },
};

const mutations = {
    set_question_group(state, questionGroup) {
        state.questionGroup = questionGroup instanceof QuestionGroup ? questionGroup : QuestionGroup.make(questionGroup);
    },

    set_simple_question_groups(state, questionGroups) {
        state.simpleQuestionGroups = questionGroups.map(qg => QuestionGroup.make(qg));
    },

    change_question_group_type(state, isCompound) {
        if (state.questionGroup.exists()) {
            return;
        }

        state.questionGroup.is_compound = isCompound;

        if (isCompound) {
            state.questionGroup.has_multiple_answers = false;
            state.questionGroup.questions.forEach(q => q.answers.forEach(a => a.amount = 0));
        } else {
            state.questionGroup.amount = 0;
        }
    },

    add_new_question(state, { questionGroupId }) {
        state.questionGroup.questions.push(Question.makeEmpty(questionGroupId));
    },

    update_question(state, question) {
        const questions = state.questionGroup.questions;
        const i = questions.findIndex(q => q.id === question.id);

        question.answers = questions[i].answers || [];
        questions.splice(i, 1, question);
    },

    delete_question(state, question) {
        const i = state.questionGroup.questions.findIndex(q => q.id === question.id);

        state.questionGroup.questions.forEach((q, i) => {
            if (q.dependent_question_id === question.id) {
                state.questionGroup.questions[i].dependent_question_id = null;
                state.questionGroup.questions[i].dependent_answer_id = null;
            }
        });

        state.questionGroup.questions.splice(i, 1);
    },

    add_new_answer(state, questionId) {
        const question = state.questionGroup.questions.find(q => q.id === questionId);

        question.answers.push(Answer.makeEmpty(question.id));
    },

    update_answer(state, answer) {
        const question = state.questionGroup.questions.find(q => q.id === answer.question_id);
        const i = question.answers.findIndex(a => a.id === answer.id);

        question.answers.splice(i, 1, answer);
    },

    delete_answer(state, answer) {
        const question = state.questionGroup.questions.find(q => q.id === answer.question_id);
        const i = question.answers.findIndex(a => a.id === answer.id);

        if (question.default_answer_id === answer.id) {
            question.default_answer_id = null;
        }

        // Remove if answer is used as default on some question
        state.questionGroup.questions.forEach((q, i) => {
            if (q.dependent_answer_id === answer.id) {
                state.questionGroup.questions[i].dependent_question_id = null;
                state.questionGroup.questions[i].dependent_answer_id = null;
            }
        });

        question.answers.splice(i, 1);
        question.default_answer_id = question.answers[0].id;
    },

    toggle_has_multiple_answers(state) {
        state.questionGroup.has_multiple_answers = !state.questionGroup.has_multiple_answers;
    },

    toggle_is_required(state) {
        state.questionGroup.is_required = !state.questionGroup.is_required;
    },

    change_parent_question(state, parentQuestionId) {
        state.questionGroup.parent_question_id = parentQuestionId || null;
        state.questionGroup.parent_suggested_answer_id = null;
    },

    change_parent_answer(state, parentAnswerId) {
        state.questionGroup.parent_suggested_answer_id = parentAnswerId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
