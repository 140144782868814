export default {
    nl: {
        general: {
            my_profile: 'Mijn profiel',
            logout: 'Uitloggen',
            general_information: 'Algemene informatie',
            configurator_questions: 'Configurator vragen',
            images: 'Afbeeldingen',
            search: 'Zoeken',
            yes: 'Ja',
            no: 'Nee',
            required: 'Verplicht',
            cancel: 'Annuleren',
            confirm: 'Bevestigen',
            homepageSettings: 'Homepage instellingen',
            save: 'Opslaan',
            recommendedProducts: 'Aanbevolen producten',
            categories: 'Categoriën voor \"Kies jouw categorie\"',
            workfield: 'Categoriën voor \"Producten op basis van werkveld\"',
            noResults: 'Geen resultaten',
            header_title:'Header titel',
            header_description:'Header omschrijving',
            header_button_text:'Header knop label',
            header_button_url:'Header knop url',
            header_image:'Header afbeelding'
        },
        images: {
            add_image: 'Voeg een afbeelding toe',
            delete_success: 'Afbeelding verwijderd',
            delete_fail: 'Afbeelding kon niet worden verwijderd',
            store_fail: 'Afbeelding kon niet worden opgeslagen',
        },
        homepage: {
            homepage: 'Startpagina',
        },
    },
}
