<template>
  <div class="menu">
    <div class="logo">
      <a href="/">
        <img :src="'/img/common/steelux.png'" />
      </a>
    </div>

    <div class="menu-items">
      <router-link to="/producten">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('products.products') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/filters">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('filters.filters') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/kortingscodes">
        <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" />
        </svg>

        {{ $t('discount_codes.codes') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/categorieen">
        <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z" />
        </svg>

        {{ $t('categories.categories') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/home-page">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('homepage.homepage') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/nieuws">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('news.news') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/keuzehulpvragen">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('choice_help.choice_help') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/redirects">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('redirects.redirects') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/faq-questions">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('faq.name') }}
      </router-link>
    </div>

    <div class="menu-items">
      <router-link to="/static">
        <svg height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM2 2H7V10L4.5 8.5L2 10V2Z" />
        </svg>
        {{ $t('static_page.title') }}
      </router-link>
    </div>

    <div class="profile">
      <div class="profile-wrapper">
        <span>{{ user }}</span>
        <div class="arrows">
          <span class="arrow-up"></span>
          <span class="arrow-down"></span>
        </div>
      </div>

      <div class="drop-down-menu">
        <a @click.prevent="$refs.logout.submit()" href="#">{{ $t('general.logout') }}</a>
      </div>

      <form action="/logout" method="POST" ref="logout">
        <input :value="csrf" name="_token" type="hidden">
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    user: document.querySelector('meta[name="user"]').getAttribute('content'),
  }),
};
</script>

