import BaseService from './index';

export default {
    getQuestionGroups(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/question-groups`, { searchQuery });
    },

    getSimpleQuestionGroups(productId) {
        return BaseService.get(`/products/${productId}/question-groups`, { type: 'simple' });
    },

    getQuestionGroup(questionGroupId) {
        return BaseService.get(`/question-groups/${questionGroupId}/show`);
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/question-groups`, params);
    },

    update(questionGroupId, params) {
        return BaseService.put(`/question-groups/${questionGroupId}`, params);
    },

    updatePosition(questionGroupId, position) {
        return BaseService.put(`/question-groups/${questionGroupId}/update-position`, { position });
    },

    delete(questionGroupId) {
        return BaseService.delete(`/question-groups/${questionGroupId}`);
    },
}
