<template>
    <draggable handle=".circle-btn" v-model="sortedQuestionGroup" @end="onEnd">
        <div v-for="questionGroup in sortedQuestionGroup">
            <div class="question-row" :class="{'question-row--has-children': questionGroup.children.length}">
                <div class="question-row-columns pointer" @click.prevent="editQuestion(questionGroup.id)">
                    <div :style="indent" class="column column-1">{{ questionGroup.questions.map(q => q.text).join(' + ') }}</div>
                    <div class="column column-2">{{ questionGroup.is_compound ? $t('questions.compound_question') : $t('articles.article') }}</div>
                    <div class="column column-right">
                        <span class="circle-btn circle-btn--no-background">
                            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 12H6V10H0V12ZM0 0V2H18V0H0ZM0 7H12V5H0V7Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>

                <question-group-list v-if="questionGroup.children" :question-list="questionGroup.children" :level="level + 1" />
            </div>
        </div>
    </draggable>
</template>

<script>
    import { ProductQuestionService } from '../../../services';
    import draggable from 'vuedraggable';

    export default {
        name: 'question-group-list',

        components: { draggable },

        props: {
            questionList: {
                type: Array,
                required: true,
            },

            level: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                sortedQuestionGroup: this.questionList.sort((a, b) => (a.position > b.position) ? 1 : -1),
            };
        },

        computed: {
            indent() {
                if (this.level === 1) {
                    return '';
                }

                return 'padding-left: ' + (this.level * 24) + 'px';
            },
        },

        methods: {
            onEnd(e) {
                ProductQuestionService.updatePosition(this.sortedQuestionGroup[e.newIndex].id, e.newIndex + 1).then(() => {
                    this.$emit('change');
                });
            },

            editQuestion(questionId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.configurator.edit',
                    params: {
                        id: productId,
                        question: questionId
                    }
                });
            },
        },
    }
</script>
