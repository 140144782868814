import { uuid } from '../../helpers';
import Question from "./Question";

export default class QuestionGroup {
    constructor(id, product_id, parent_question_id, parent_suggested_answer_id, amount, is_compound, is_required, has_multiple_answers, children, questions) {
        this.id = !id ? uuid() : id;
        this.product_id = product_id;
        this.parent_question_id = parent_question_id;
        this.parent_suggested_answer_id = parent_suggested_answer_id;
        this.amount = amount;
        this.is_compound = is_compound;
        this.is_required = is_required;
        this.has_multiple_answers = has_multiple_answers;
        this.has_children = !!(children.length);
        this.questions = questions.map(q => Question.make(q)) || [];
    }

    static make(obj) {
        return new this(obj.id, obj.product_id, obj.parent_question_id, obj.parent_suggested_answer_id, obj.amount, obj.is_compound, obj.is_required, obj.has_multiple_answers, obj.children, obj.questions);
    }

    static makeEmpty(product_id) {
        return new this(null, product_id, null, null, 0, false, false, false, [], []);
    }

    exists() {
        return !this.id.toString().startsWith('_');
    }
}
