<template>
  <div class="half-size">
    <div class="field" v-if="category.category_id">
      <label class="label">{{ $t('categories.option') }}</label>
      <div class="field-control">
        <multiselect :options="options"
                     @close="parentCategoryChanged"
                     label="name"
                     placeholder="Selecteer een categorie"
                     track-by="id"
                     v-model="category.parent_category" />
      </div>
    </div>
    <div class="field" v-if="category.category_id !== null">
      <label class="label">{{ $t('categories.header') }}</label>
      <div class="field-control">
        <multiselect :options="dividers"
                     label="divider_label"
                     placeholder="Selecteer"
                     @close="dividerChanged"
                     track-by="id"
                     v-model="category.sub_category_divider" />
      </div>
    </div>

    <div class="field">
      <label class="label">Naam</label>
      <div class="field-control">
        <input type="text" v-model="category.name" />
      </div>
    </div>
    <div class="field">
      <label class="label">URL</label>
      <div class="field-control">
        <input type="text" v-model="category.url" />
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $t('categories.sort_method') }}</label>
      <div class="field-control">
        <multiselect :options="sortMethods"
                     label="label"
                     placeholder="Selecteer een sorteer methode"
                     @close="sortMethodChanged"
                     track-by="id"
                     v-model="sortMethod" />
      </div>
    </div>
    <div v-if="category.category_id === null" @click="category.show_navbar = !category.show_navbar"
         class="field field--checkbox">
      <input :checked="category.show_navbar" type="checkbox" />
      <div class="field-control">
        <label>Tonen in menu</label>
      </div>
    </div>

    <div class="field field--image">
      <label class="label">Afbeelding</label>
      <div class="field-control" v-if="category.path === null">
        <label for="image-input" class="btn btn--blue">
          {{ $t('images.add_image') }}
        </label>

        <input id="image-input"
               name="images"
               type="file"
               accept="image/*"
               @change="fileInputChange($event)">
      </div>
      <div class="field-control" v-else>
        <div class="image-container">
          <img class="image" :src="category.path" alt="">
          <div class="icon" @click="category.path = null">
            <img src="/svgs/close-icon.svg" alt="close-icon">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CategoryService, DividerService } from "../../../services";
import multiselect from "vue-multiselect";

export default {
  name: "GeneralInformationTab",
  components: {
    multiselect,
  },
  methods: {
    dividerChanged() {
      if (this.category.sub_category_divider && this.category.sub_category_divider.id) {
        this.category.sub_category_divider_id = this.category.sub_category_divider.id
      }
    },
    sortMethodChanged() {
      this.category.sort_method_id = this.sortMethod.id;
    },
    parentCategoryChanged() {
      this.category.category_id = this.category.parent_category.id
    },
    saveSettings() {
      this.$store.commit('categories/set_category', this.category)
    },
    fileInputChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const reader = new FileReader();

      if (!files.length) {
        return;
      }
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        this.category.path = e.target.result;
      };
    },
    fetchDividers() {
      DividerService.index(this.category.category_id).then(response => {
        this.dividers = response.data
      });
    }
  },
  props: {
    category: {
      required: true,
    },
  },
  data() {
    return {
      options: [],
      dividers: [],
      subCategoryOptions: [],
      sortMethods: [],
      sortMethod: null,
    }
  },
  mounted() {
    this.fetchDividers();
    this.sortMethod = this.category.sort_method;
  },
  beforeRouteEnter(to, from, next) {
    CategoryService.sortMethods().then(sortMethodResponse => {
      let sortMethods = sortMethodResponse.data;
      CategoryService.index().then(CategoryResponse => {
        let options = CategoryResponse.data;
        next(vm => {
          vm.options = options;
          vm.sortMethods = sortMethods;
        });
      });
    });
  },
}
</script>
