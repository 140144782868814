<template>
    <div class="category-detail">
        <div class="page-header">
            <h1 class="page-title">{{ category.name }}</h1>
            <a @click.prevent="save" class="btn btn--orange" href="#">
                {{ $t('categories.save_data') }}
            </a>
        </div>

        <div class="tabs tabs--push-less">
            <router-link :to="`/categorieen/${category.id}/algemene-gegevens`">
                {{ $t('general.general_information') }}
            </router-link>
            <router-link v-if="category.category_id === null" :to="`/categorieen/${category.id}/labels`">
                {{ $t('categories.category_dividers') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/seo-content`">
                {{ $t('categories.seo_content') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/instellingen`">
                {{ $t('categories.settings') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/filters`">
                {{ $t('categories.filters') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/producten`">
                {{ $t('categories.products') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/meta`">
                {{ $t('categories.meta') }}
            </router-link>
            <router-link v-if="category.category_id === null" :to="`/categorieen/${category.id}/keuzehulp`">
                {{ $t('categories.choice_help') }}
            </router-link>
        </div>
        <router-view :category="category" />
    </div>
</template>

<script>
    import store from '../../../store';

    export default {
        async beforeRouteEnter(to, from, next) {
            await store.dispatch('categories/fetch_category', to.params.id).then(response => {
                next();
            });
        },
        beforeRouteLeave(to, from, next) {
            this.$store.commit('categories/clear_category');
            next()
        },
        name: "CategoryDetail",
        computed: {
            category() {
                return this.$store.state.categories.category;
            },
        },
        methods: {
            save() {
                let promise = this.$store.dispatch('categories/update_category');

                promise.then(() => {
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                });

                promise.catch(err => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                })
            },
        },
    }
</script>
