<template>
    <div>
        <div class="field">
            <label class="label">{{ $t('categories.choice_help_description') }}</label>
            <div class="field-control">
                <textarea placeholder="Type hier uw beschrijving tekst.." v-model="category.choice_help_description"></textarea>
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('categories.choice_help_question') }}</label>
            <div class="field-control">
                <multiselect v-model="category.choice_help_question"
                             :options="questions"
                             label="name"
                             track-by="id"
                             :close-on-select="true"
                             :taggable="true"
                             placeholder=""
                             :multiple=false />
            </div>
        </div>
        <div @click="category.choice_assist_show_menu = !category.choice_assist_show_menu" class="field field--checkbox">
            <input :checked="!!category.choice_assist_show_menu" type="checkbox" />
            <div class="field-control">
                <label>{{ $t('categories.choice_help_show_menu') }}</label>
            </div>
        </div>
        <div @click="category.choice_assist_show_page = !category.choice_assist_show_page" class="field field--checkbox">
            <input :checked="!!category.choice_assist_show_page" type="checkbox" />
            <div class="field-control">
                <label>{{ $t('categories.choice_help_show_page') }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import multiselect from 'vue-multiselect'
    import { ChoiceHelpService } from '../../../services';

    export default {
        components: {
            multiselect
        },
        data() {
            return {
                questions: [],
            }
        },
        props: {
            category: {
                required: true
            },
        },
        methods: {
            fetchQuestions() {
                ChoiceHelpService.all().then(response => {
                    this.questions = response.data;
                });
            },
        },
        mounted() {
            this.fetchQuestions();
        },
        name: "ChoiceHelpTab"
    }
</script>
