<template>
    <div class="product-detail">
        <div class="page-header">
            <h1 class="page-title">Nieuws</h1>
        </div>

        <div class="half-size">
            <div class="field field--checkbox" style="margin-top: 48px">
                <input type="checkbox" id="isVisible" v-model="article.is_visible" />
                <div class="field-control">
                    <label for="isVisible">Zichtbaar</label>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('news.title') }}</label>
                <div class="field-control">
                    <input type="text" v-model="article.title" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('news.category') }}</label>
                <div class="field-control">
                    <multiselect v-model="article.categories"
                                 :options="newsCategories"
                                 label="name"
                                 track-by="id"
                                 :close-on-select="false"
                                 :limit="3"
                                 :taggable="true"
                                 placeholder="Selecteer categorie "
                                 :multiple=true />
                </div>
            </div>

            <div class="field">
                <label class="label" for="header_image_input">{{ $t('news.header_image') }}</label>
                <div class="images-container news">
                    <label v-if="article.header_image && !article.header_image.length > 0"
                           for="header_image_input"
                           class="btn btn--blue">
                        {{ $t('images.add_image') }}
                    </label>

                    <input id="header_image_input"
                           name="image-input"
                           accept="image/*"
                           type="file"
                           @change="(e) => { handleImageUpload(e,article.header_image) }">

                    <div class="images news">
                        <div v-if="article.header_image && article.header_image[0]" class="image">
                            <div @click="deleteImage(0,article.header_image)">
                                <img src="/svgs/close-icon.svg" alt="close-icon">
                            </div>
                            <img class="preview" v-if="article.header_image[0].full_path"
                                 :src="article.header_image[0].full_path" />
                            <img class="preview" v-else :src="article.header_image[0].path" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="field">
                <label class="label" for="text-row-1">{{ $t('news.text_row_1') }}</label>
                <Redactor placeholder="Type hier uw beschrijving.." id="text-row-1" v-model="article.text_row_1"
                          :config="redactorConfig" />
            </div>

            <div class="field">
                <label class="label" for="first_row_images_input">{{ $t('news.first_row_images') }}</label>
                <div class="images-container news">
                    <label v-if="article.first_row_images && article.first_row_images.length < 10"
                           for="first_row_images_input"
                           class="btn btn--blue">
                        {{ $t('images.add_image') }}
                    </label>

                    <input id="first_row_images_input"
                           name="image-input"
                           accept="image/*"
                           type="file"
                           @change="(e) => { handleImageUpload(e,article.first_row_images) }">

                    <div class="images news">
                        <draggable v-model="article.first_row_images">
                            <div v-for="(data,index) in article.first_row_images" class="image">
                                <div @click="deleteImage(index,article.first_row_images)">
                                    <img src="/svgs/close-icon.svg" alt="close-icon">
                                </div>
                                <img class="preview" v-if="data.full_path" :src="data.full_path" />
                                <img class="preview" v-else :src="data.path" />
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>

            <div class="field">
                <label class="label" for="text-row-2">{{ $t('news.text_row_2') }}</label>
                <Redactor placeholder="Type hier uw beschrijving.." id="text-row-2" v-model="article.text_row_2"
                          :config="redactorConfig" />
            </div>

            <div class="field">
                <label class="label" for="second_row_images_input">{{ $t('news.second_row_images') }}</label>
                <div class="images-container news">
                    <label v-if="article.second_row_images && article.second_row_images.length < 10"
                           for="second_row_images_input"
                           class="btn btn--blue">
                        {{ $t('images.add_image') }}
                    </label>

                    <input id="second_row_images_input"
                           name="image-input"
                           accept="image/*"
                           type="file"
                           @change="(e) => { handleImageUpload(e,article.second_row_images) }">

                    <div class="images news">
                        <draggable v-model="article.second_row_images">
                            <div v-for="(data,index) in article.second_row_images" class="image">
                                <div @click="deleteImage(index,article.second_row_images)">
                                    <img src="/svgs/close-icon.svg" alt="close-icon">
                                </div>
                                <img class="preview" v-if="data.full_path" :src="data.full_path" />
                                <img class="preview" v-else :src="data.path" />
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('news.youtube') }}</label>
                <div class="field-control">
                    <input type="text" v-model="article.youtube_url" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('news.products') }}</label>
                <div class="field-control">
                    <multiselect v-model="article.products"
                                 :options="products"
                                 label="name"
                                 track-by="id"
                                 :close-on-select="false"
                                 :taggable="true"
                                 placeholder="Selecteer gerelateerde producten"
                                 :multiple=true />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('news.relevant_articles') }}</label>
                <div class="field-control">
                    <multiselect v-model="article.relevant_news_articles"
                                 :options="newsArticles"
                                 label="title"
                                 track-by="id"
                                 :close-on-select="false"
                                 :taggable="true"
                                 placeholder="Selecteer relevante artikelen "
                                 :multiple=true />
                </div>
            </div>
        </div>

        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('news.save') }}
        </a>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import Redactor from '../../components/redactor-text-area'
    import { NewsArticleService, ProductService } from "../../services";
    import draggable from 'vuedraggable';
    import imageCompression from 'browser-image-compression';

    export default {
        components: { Multiselect, Redactor, draggable },

        name: 'article-detail',

        data() {
            return {
                article: {},
                products: [],
                newsArticles: [],
                newsCategories: [],
                redactorConfig: {
                    buttons: ['bold', 'html', 'lists', 'italic','format'],
                    formatting: ['p', 'h1', 'h2']
                },
            };
        },


        beforeMount() {
            this.fetchArticle();
            this.fetchNewsCategories();
            this.fetchProducts();
        },

        methods: {
            addImage(file, imageArray) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    imageArray.push({
                        id: null,
                        path: e.target.result,
                        position: imageArray.length + 1,
                    });
                };
            },

            async handleImageUpload(e, imageArray) {
                const imageFile = e.target.files[0];
                const options = {
                    maxSizeMB: 3,
                    maxWidthOrHeight: 1680,
                    useWebWorker: false
                };
                try {
                    const compressedFile = await imageCompression(imageFile, options);
                    this.addImage(compressedFile, imageArray)
                } catch (error) {
                    console.error(error);
                }
            },
            deleteImage(index, array) {
                array.splice(index, 1)
            },
            fetchProducts() {
                ProductService.all().then(response => {
                    this.products = response.data
                })
            },
            fetchNewsCategories() {
                NewsArticleService.categories(this.$route.params.id).then(response => {
                    this.newsCategories = response.data
                });
            },
            fetchArticle() {
                NewsArticleService.show(this.$route.params.id).then(response => {
                    this.article = response.data;
                    NewsArticleService.all({ id: this.article.id }).then(response => {
                        this.newsArticles = response.data;
                    });
                });
            },
            save() {
                let data = Object.assign({}, this.article);
                data.categories = this.article.categories.map(({ id }) => id);
                data.products = this.article.products.map(({ id }) => id);
                data.relevant_news_articles = this.article.relevant_news_articles.map(({ id }) => id);

                //set position correct of images
                data.first_row_images = this.article.first_row_images.map((image, index) => {
                    image.position = index + 1;
                    return image;
                });

                //set position correct of images
                data.second_row_images = this.article.second_row_images.map((image, index) => {
                    image.position = index + 1;
                    return image;
                });

                NewsArticleService.update(this.$route.params.id, data, { headers: { 'content-type': 'multipart/form-data' } }).then(response => {
                    this.article = response.data;
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");
                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    }
</script>
