import BaseService from './index';

export default {
    index(categoryId,params) {
        return BaseService.get(`/categories/${categoryId}/dividers`, params);
    },

    store(categoryId,params) {
        return BaseService.post(`/categories/${categoryId}/dividers`, params);
    },

    show(categoryId,dividerId) {
        return BaseService.get(`/categories/${categoryId}/dividers/${dividerId}`);
    },

    update(categoryId,dividerId, params) {
        return BaseService.post(`/categories/${categoryId}/dividers/${dividerId}`, params);
    },
}
