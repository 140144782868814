<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('filters.filter') }}</h1>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.filterCategory" @click.prevent="isDeleteModalActive = true">
                    {{ $t('filters.delete') }}
                </a>

                <a href="#" class="btn btn--orange" @click.prevent="saveFilter">
                    {{ $t('filters.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('filters.title') }}</label>
                <div class="field-control">
                    <select v-model="selectedFilterCategory">
                        <option v-for="filterCategory in filterCategories" :value="filterCategory">{{ filterCategory.name }}</option>
                    </select>
                </div>
            </div>

            <div class="field" v-if="selectedFilterCategory">
                <label class="label">{{ $t('filters.option') }}</label>
                <div class="field-control">
                    <multiselect v-model="selectedFilterOptions"
                                 :options="selectedFilterCategory.filters"
                                 label="option"
                                 :multiple="true"
                                 track-by="id"
                                 placeholder="Selecteer opties" />
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('filters.delete_modal.title')"
                   :content="$t('filters.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteProductFilterCategory" />
    </div>
</template>

<script>
    import { FilterService } from '../../services';
    import BaseModal from '../../components/BaseModal';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'product-filters',

        components: {
            Multiselect,
            BaseModal,
        },

        beforeRouteEnter(to, from, next) {
            if (to.params.filterCategory) {
                FilterService.getProductFilters(to.params.id).then(response => {
                    next(vm => {
                        vm.selectedFilterCategory = response.data.filter(f => f.filter_category_id === parseInt(to.params.filterCategory))[0].filter_category;
                        vm.selectedFilterOptions = response.data.filter(f => f.filter_category_id === parseInt(to.params.filterCategory));
                    })
                }).catch((err) => {
                    next({
                        name: 'products.filters',
                        params: { id: to.params.id },
                    });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                selectedFilterCategory: null,
                selectedFilterOptions: [],
                filterCategories: [],
                isDeleteModalActive: false,
            };
        },

        beforeMount() {
            this.fetchFilters();
        },

        methods: {
            fetchFilters() {
                const params = {
                    filters: {
                        name: this.searchQuery,
                    }
                };

                const promise = FilterService.index(params);

                promise.then(response => {
                    const productId = this.$route.params.id;
                    const filterCategoryId = parseInt(this.$route.params.filterCategory);

                    if (filterCategoryId) {
                        this.filterCategories = response.data.filter(f => f.id === filterCategoryId);
                    } else {
                        FilterService.getProductFilters(productId).then(productFiltersResponse => {
                            const productFilterCategories = productFiltersResponse.data.map(f => f.filter_category_id);
                            this.filterCategories = response.data.filter(f => !productFilterCategories.includes(f.id));
                        });
                    }
                });

                return promise;
            },

            saveFilter() {
                const productId = this.$route.params.id;
                const filterCategoryId = this.$route.params.filterCategory;

                const params = {
                    filterCategory: this.selectedFilterCategory.id,
                    filters: this.selectedFilterOptions.map(f => f.id),
                };

                if (filterCategoryId) {
                    params.filterCategory = filterCategoryId;
                }

                FilterService.updateProductFilter(productId, params).then(response => {
                    this.$flashMessage(this.$t('filters.save_success'), 3500, 'success');
                    this.$router.push({ name: 'products.filters', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            deleteProductFilterCategory() {
                const productId = this.$route.params.id;
                const filterCategoryId = this.$route.params.filterCategory;

                FilterService.deleteProductFilter(productId, filterCategoryId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.filters',
                            params: { id: productId },
                        }
                    );
                });
            },
        },
    };
</script>
